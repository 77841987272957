import { PersonPin } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useOrderApi } from "api/OrderApi/order-api";
import { setOrderEmployee } from "components/order/order-events";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiResultStatus } from "utils/enums";
import { IEmployeeSelectProps } from "./interfaces";
import { css } from "@emotion/css";

export const EmployeeSelect: React.FC<IEmployeeSelectProps> = (
  props: IEmployeeSelectProps
) => {
  const { t } = useTranslation(["commonTranslation", "employeeTranslation"]);
  const { setEmployee } = useOrderApi();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number|undefined>(props.selectedEmloyeeId);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleSave();
  };

  const handleSave = async (): Promise<void> => {
    // let selectedEmployeeId = selectedId ? parseInt(selectedId) : null;
    await setEmployee({
      employeeId: selectedId,
      orderId: props.orderId,
    }).then((response) => {
      if (response.status === ApiResultStatus.Ok) {
        setOrderEmployee({
          employeeId: selectedId,
          orderId: props.orderId,
        });
      }
      setOpen(false);
    });
  };

  return (
    <>
      <IconButton color="primary" component="label" onClick={handleClickOpen}>
        <PersonPin />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle>{t("employeeTranslation:selectEmployee")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("employeeTranslation:employeeSelectInfo")}
          </DialogContentText>
          <Grid container spacing={1} justifyContent={"left"}>
            {_.map(
              _.sortBy(
                props.employeeList,
                (data) => `${data.firstName} ${data.lastName}`
              ),
              (d) => (
                <Grid item xs={4}>
                  <Button
                    variant={d.id === selectedId ? "contained" : "outlined"}
                    fullWidth={true}
                    onClick={() => {
                      if (selectedId !== d.id) {
                        setSelectedId(d.id);
                      } else {
                        setSelectedId(undefined);
                      }
                    }}
                    className={css`
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    `}
                  >{`${d.firstName} ${d.lastName}`}</Button>
                </Grid>
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button variant="contained" onClick={handleClose}>
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
