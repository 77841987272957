import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DivIcon } from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { ILocationMarkerProps } from "./interfaces";

export const LocationMarker: React.FC<ILocationMarkerProps> = (
  props: ILocationMarkerProps
) => {
  const pointEPSG4326: proj4.TemplateCoordinates = [
    props.markerData.longitude ?? 0,
    props.markerData.latitude ?? 0,
  ];

  const icon = new DivIcon({
    html: ReactDOMServer.renderToString(
      <FontAwesomeIcon
        icon={faHouse}
        size="lg"
        className="icon-marker"
        color="#f18113"
      />
    ),
    iconSize: [27, 24],
    iconAnchor: [27 / 2, 24],
    popupAnchor: [0, -24],
    tooltipAnchor: [0, -20],
  });

  return (
    <Marker
      position={[pointEPSG4326[1], pointEPSG4326[0]]}
      key={`location${props.markerData.id},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
      icon={icon}
    ></Marker>
  );
};
