import { TFunction } from "react-i18next";
import { OrderDeliveryStatus, PaymentType } from "./enums";

export const getOrderDeliveryStatusEnumTranslation: (
  enumValue: OrderDeliveryStatus,
  t: TFunction
) => string = (enumValue: OrderDeliveryStatus, t: TFunction): string => {
  switch (enumValue) {
    case OrderDeliveryStatus.New:
      return t("enumTranslation:orderDeliveryStatus.new");
    case OrderDeliveryStatus.Delivered:
      return t("enumTranslation:orderDeliveryStatus.delivered");
    case OrderDeliveryStatus.NoOneOpened:
      return t("enumTranslation:orderDeliveryStatus.noOneOpened");
    case OrderDeliveryStatus.ToBeDelivered:
      return t("enumTranslation:orderDeliveryStatus.toBeDelivered");
      case OrderDeliveryStatus.ReadyForCollect:
      return t("enumTranslation:orderDeliveryStatus.readyForCollect");
    default:
      return "";
  }
};

export const getPaymentTypeEnumTranslation: (
  enumValue: PaymentType,
  t: TFunction
) => string = (enumValue: PaymentType, t: TFunction): string => {
  switch (enumValue) {
    case PaymentType.Other:
      return t("enumTranslation:paymentType.other");
    case PaymentType.Cash:
      return t("enumTranslation:paymentType.cash");
    case PaymentType.Card:
      return t("enumTranslation:paymentType.card");
    case PaymentType.Online:
      return t("enumTranslation:paymentType.online");
    case PaymentType.Voucher:
      return t("enumTranslation:paymentType.voucher");
    default:
      return "";
  }
};
