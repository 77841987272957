import moment from "moment";
import { IEmployeeColorContainerProps } from "./interfaces";
import { Typography } from "@mui/material";

export const EmployeeColorContainer: React.FC<IEmployeeColorContainerProps> = (
  props: IEmployeeColorContainerProps
) => {
  return (
    <div
      style={{
        height: "100%",
        borderTopLeftRadius: "2px",
        borderBottomLeftRadius: "2px",
        backgroundColor: props.color,
        width: "100%",
        textAlign: "center",
        color: props.isWhiteFontColorEnabled ? "white" : "inherit",
      }}
    >
      <Typography variant="h6" noWrap={true} paddingTop={"10px"}>
        {props.plannedReturnTime
          ? moment(props.plannedReturnTime).diff(moment(), "m")
          : "-"}
      </Typography>
      <Typography variant="subtitle2" noWrap={true}>
        min
      </Typography>
    </div>
  );
};
