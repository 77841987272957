import { useBaseApi } from "api/base-api";
import _ from "lodash";
import { IEmployeeApi } from "./interfaces";
import { IEmployeeGetListResult } from "./interfaces-result";
import { IResultBaseGeneric } from "api/interfaces";

export const useEmployeeApi: () => IEmployeeApi = (): IEmployeeApi => {
  const { baseGetRequest } = useBaseApi();

  const getList: () => Promise<IResultBaseGeneric<IEmployeeGetListResult[]>> =
    async (): Promise<IResultBaseGeneric<IEmployeeGetListResult[]>> => {
      let url: string = "/Employee/GetList";
      return await baseGetRequest<IResultBaseGeneric<IEmployeeGetListResult[]>>(url);
    };

  return { getList };
};
