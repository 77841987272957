import { styled, Typography, Theme, Grid } from "@mui/material";

export const EmployeeLastSyncText = styled(Typography)(
  ({ theme }) => `
  border-radius: 4px;
  text-align: right;
  padding-right: 6px;
`
);

export const EmployeeCardGrid = styled(Grid)(
  ({ theme }) => `
  border: 2px solid transparent;
  border-radius: 4px;
`
);

export const EmployeeColorGrid = styled(Grid)(
  ({ theme }) => `
  border: 2px solid transparent;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  height: 100%;
  padding: 2px;
  width: 100%;
`
);