import _ from "lodash";
import { MapContainer, TileLayer } from "react-leaflet";
import { IMapBasicProps, IMarker } from "./interfaces";
import { Button, Paper } from "@mui/material";
import { OrderMarker } from "./order/order-marker";
import { useEffect, useRef, useState } from "react";
import { EmployeeMarker } from "./employee/employee-marker";
import { LocationMarker } from "./location/location-marker";
import { VisibilityOff } from '@mui/icons-material'
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { ILocationGetDetailsResult } from "api/LocationApi/interfaces-result";

export const MapBasic: React.FC<IMapBasicProps> = (props: IMapBasicProps) => {
  const [defaultLocalization, setDefaultLocalization] = useState<number[]>([
    51.764825, 19.435633,
  ]);
  const [defaultMarker, setDefaultMarker] = useState<IMarker | null>(null);
  const [locationDetails, setLocationDetails] = useState<ILocationGetDetailsResult>({...props.locationDetails});
  const mapRef: any = useRef();
  const { t } = useTranslation(["commonTranslation"]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo([defaultLocalization[0], defaultLocalization[1]]);
    }
  }, [defaultLocalization]);

  useEffect(() => {
       if (locationDetails?.latitude && locationDetails?.longitude) {
         setDefaultLocalization([
           locationDetails.latitude,
           locationDetails.longitude,
         ]);
         setDefaultMarker({
           id: 0,
           latitude: locationDetails.latitude,
           longitude: locationDetails.longitude,
         });
       }
  }, [locationDetails]);

  return (
    <Paper variant="elevation" elevation={3} sx={{ height: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <MapContainer
          center={[defaultLocalization[0], defaultLocalization[1]]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
          whenCreated={(map) => {
            mapRef.current = map;
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
          />
          {_.map(props.orderMarkerList, (marker) => {
            return <OrderMarker markerData={marker} mapRef={mapRef} />;
          })}
          {_.map(props.employeeMarkerList, (marker) => {
            return <EmployeeMarker markerData={marker} />;
          })}
          {defaultMarker && <LocationMarker markerData={defaultMarker} />}
            {/* <Button
              color="inherit"
              title={t("hideMap")}
              className={css`
                background-color: white !important;
                border: 2px solid rgba(0,0,0,0.2) !important;
                position: absolute;
                top: 10px !important;
                right: 10px !important;
                z-index: 1000 !important;
                &:hover {
                  background-color: #f4f4f4 !important;
                }
              `}
              onClick={() => {
                handleHideMap();
              }}
            >
              <VisibilityOff />
            </Button> */}
        </MapContainer>
      </div>
    </Paper>
  );
};
