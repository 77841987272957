import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiResultStatus } from "utils/enums";
import { useLocationApi } from "api/LocationApi/location-api";
import { IOrderDefaultReadyTimeChangeProps } from "./interfaces";

export const LocationDefaultReadyTimeChange: React.FC<IOrderDefaultReadyTimeChangeProps> = (
  props: IOrderDefaultReadyTimeChangeProps
) => {
  const { t } = useTranslation(["commonTranslation"]);
  const { setDefaultOrderReadyTimeInMinutes } = useLocationApi();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDefaultOrderReadyTimeInMinutes, setSelectedDefaultOrderReadyTimeInMinutes] = useState<number>(props.defaultOrderReadyTimeInMinutes);
  const availableItems: number[] = [15, 30, 45, 60, 75, 90];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedDefaultOrderReadyTimeInMinutes(props.defaultOrderReadyTimeInMinutes);
    setOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    await setDefaultOrderReadyTimeInMinutes({
      defaultOrderReadyTimeInMinutes: selectedDefaultOrderReadyTimeInMinutes,
    }).then((response) => {
      if (response.status === ApiResultStatus.Ok) {
        props.handleSetDefaultOrderReadyTimeInMinutesOnSave(selectedDefaultOrderReadyTimeInMinutes);
      }
      setOpen(false);
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        title={t("defaultOrderReadyTime")}
        onClick={handleClickOpen}
      >
        {props.defaultOrderReadyTimeInMinutes} min
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>{t("defaultReadyTimeChangeTitle")}</DialogTitle>
        <DialogContent>
        <Grid
            container
            spacing={1}
            justifyContent={"left"}
          >
            {_.map(availableItems, (item) => (
               <Grid item xs={2}>
               <Button
                 variant={item === selectedDefaultOrderReadyTimeInMinutes ? "contained" : "outlined"}
                 fullWidth={true}
                 onClick={() => {
                     setSelectedDefaultOrderReadyTimeInMinutes(item);
                 }}
               >{`${item} min`}</Button>
             </Grid>
              ))}
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button variant="contained" onClick={handleSave}>
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

