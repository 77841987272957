import { IContextAuthorization } from "contexts/interfaces";
import { useState } from "react";
import { DEFAULT_USER_AUTH_TOKEN } from "utils/consts";

const useAuthorization: (initialState: string) => IContextAuthorization = (
  initialState: string
): IContextAuthorization => {
  const [authToken, setAuthToken] = useState(initialState);

  const setAuthorize: (authToken: string) => void = (
    authToken: string
  ): void => {
    window.sessionStorage.setItem("UserAuthToken", authToken);
    setAuthToken(authToken);
  };

  const setUnauthorize: () => void = (): void => {
    window.sessionStorage.clear();
    setAuthToken(DEFAULT_USER_AUTH_TOKEN);
  };

  return { authToken, setAuthorize, setUnauthorize };
};

export default useAuthorization;
