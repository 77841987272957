import axios, { AxiosInstance } from "axios";
import { SweetAlertResult } from "sweetalert2";
import { IBaseApiInterface } from "./interfaces";
import { useTranslation } from "react-i18next";
import useAuthorization from "hooks/use-authorization";
import { getStoredUserAuthToken } from "utils/helpers";
import useModalError from "hooks/use-modal-error";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ??
  "https://kdiwebapi.deliverysupportsystem.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${envBaseURL}/${envApiVersion}`,
  headers: {
    "Access-Control-Allow-Origin": true,
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    "Content-Type": "application/json",
    "Content-": "application/json",
  },
});

export function useBaseApi(): IBaseApiInterface {
  const { t } = useTranslation(["commonTranslation"]);
  const { setUnauthorize } = useAuthorization(getStoredUserAuthToken());
  const showModalError: (errorResult: string) => Promise<SweetAlertResult> =
    useModalError();
  const navigate = useNavigate();

  const handleError: (error: any) => void = (error: any): void => {
    if (error.response && error.response.status === 401) {
      showModalError(t("sessionTimeout")).then(() => {
        setUnauthorize();
        navigate("/");
      });
    } else if (error.response && error.response.status === 406) {
      showModalError(error.response.data.statusMessage);
    } else {
      showModalError(t("globalError"));
    }
  };

  async function baseDeleteRequest<T>(
    apiUrl: string,
    withAutorization: boolean = true
  ): Promise<T> {
    return await axiosInstance
      .delete(apiUrl, {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function baseGetRequest<T>(
    apiUrl: string,
    withAutorization: boolean = true
  ): Promise<T> {
    return await axiosInstance
      .get(apiUrl, {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function baseGetRequestWithModel<modelT extends Object, resultT>(
    apiUrl: string,
    model: modelT,
    withAutorization: boolean = true
  ): Promise<resultT> {
    let paramsArray: string[] = _.map(model, (value, key) => {
      return `${key}=${value!= undefined && value != null ? value : ''}`;
    });
    let params: string = _.join(paramsArray, "&");
    apiUrl += `?${params}`;

    return baseGetRequest<resultT>(apiUrl, withAutorization);
  }

  async function basePatchRequest<modelT, resultT>(
    apiUrl: string,
    model: modelT,
    withAutorization: boolean = true
  ): Promise<resultT> {
    return await axiosInstance
      .patch(apiUrl, JSON.stringify(model), {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function basePostRequest<modelT, resultT>(
    apiUrl: string,
    model: modelT,
    withAutorization: boolean = true
  ): Promise<resultT> {
    return await axiosInstance
      .post(apiUrl, JSON.stringify(model), {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  return {
    baseDeleteRequest,
    baseGetRequest,
    baseGetRequestWithModel,
    basePatchRequest,
    basePostRequest,
  };
}
