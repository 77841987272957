import { Alert, Box, Button, Grid, IconButton, Paper } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { EmployeeCard } from "./employee-card";
import { IEmployeeListProps } from "./interfaces";
import { useEffect, useState } from "react";
import { IEmployeeGetListResult } from "api/EmployeeApi/interfaces-result";
import { css } from "@emotion/css";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export const EmployeeList: React.FC<IEmployeeListProps> = (
  props: IEmployeeListProps
) => {
  const { t } = useTranslation(["employeeTranslation"]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pagedEmployeeList, setPagedEmployeeList] = useState<IEmployeeGetListResult[]>([]);

  const createPagedEmployeeList = () => {
    let newFilteredItems:IEmployeeGetListResult[] = [];
    if(props.isSingleRowMode){
      let pageItemsCount = 12 / props.itemSize;
      for (
        let i = (pageNo - 1) * pageItemsCount;
        i < pageNo * pageItemsCount && i < props.employeeList.length;
        i++
      ) {
        newFilteredItems.push({ ...props.employeeList[i] });
      }
    }else{
      newFilteredItems = [...props.employeeList];
    }
    setPagedEmployeeList(newFilteredItems);
  };

  useEffect(() => {
    createPagedEmployeeList();
  }, [pageNo, props.employeeList]);

  useEffect(() => {
    setPageNo(1);
    createPagedEmployeeList();
  }, [props.itemSize, props.isSingleRowMode]);

  return (
    <Paper
      variant="elevation"
      elevation={3}
      sx={{ padding: "1rem", height: (props.isSingleRowMode ? "none" : "100%"), overflow:"auto" }}
    >
      <Box>
        {props.employeeList.length === 0 && (
          <Grid container spacing={1} justifyContent={"left"}>
            <Grid item xs={12}>
              <Alert severity="info">{t("noLoggedEmployees")}</Alert>
            </Grid>
          </Grid>
        )}
        {props.employeeList.length > 0 && (
          <Box
            className={css`
              position: relative;
            `}
          >
            {props.isSingleRowMode && (
              <>
                <IconButton
                  className={css`
                    position: absolute;
                    left: 0;
                    top: 8px;
                  `}
                  onClick={() => {
                    setPageNo(Math.max(pageNo - 1, 1));
                  }}
                >
                  <ArrowUpward />
                </IconButton>
                <IconButton
                  className={css`
                    position: absolute;
                    left: 0;
                    bottom: 0;
                  `}
                  onClick={() => {
                    setPageNo(
                      Math.min(
                        pageNo + 1,
                        Math.ceil(
                          props.employeeList.length / (12 / props.itemSize)
                        )
                      )
                    );
                  }}
                >
                  <ArrowDownward />
                </IconButton>
              </>
            )}
            <Grid
              container
              spacing={1}
              justifyContent={"left"}
              className={css`
                ${props.isSingleRowMode
                  ? "width: calc(100% - 36px);margin-left: 36px;"
                  : ""}
              `}
            >
              {_.map(pagedEmployeeList, (data) => (
                <Grid item xs={props.itemSize}>
                  <EmployeeCard {...data} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
