import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useOrderApi } from "api/OrderApi/order-api";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiResultStatus, OrderDeliveryStatus } from "utils/enums";
import { OrderParametersDialogProps } from "./interfaces";
import { css } from "@emotion/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { getOrderDeliveryStatusEnumTranslation } from "utils/enums-helper";
import { setOrderParameters } from "./order-events";

export const OrderParametersDialog: React.FC<OrderParametersDialogProps> = (
  props: OrderParametersDialogProps
) => {
  const { t } = useTranslation(["commonTranslation", "enumTranslation"]);
  const { setParameters } = useOrderApi();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedNewDeliveryStatus, setSelectedNewDeliveryStatus] =
    useState<OrderDeliveryStatus | undefined>(undefined);

  const handleClickOpen = () => {
    setSelectedNewDeliveryStatus(undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (): Promise<void> => {
    if (selectedNewDeliveryStatus) {
      await setParameters({
        orderId: props.orderId,
        deliveryStatus: selectedNewDeliveryStatus,
      }).then(() => {
        setOrderParameters({
          orderId: props.orderId,
          deliveryStatus: selectedNewDeliveryStatus
        });
        setOpen(false);
      });
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton color="primary" component="label" onClick={handleClickOpen}>
        <FontAwesomeIcon icon={faEdit} size="sm" title="" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle>{t("orderParameters")}</DialogTitle>
        <DialogContent>
          <div></div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <h3
                      className={css`
                        margin: 0;
                      `}
                    >
                      {t("orderStatus")}:
                    </h3>
                  </td>
                  <td
                    rowSpan={2}
                    className={css`
                      padding-left: 10px;
                    `}
                  >
                    <span>
                      {props.deliveryStatus ===
                        OrderDeliveryStatus.ReadyForCollect && (
                        <Button
                          variant={
                            selectedNewDeliveryStatus === undefined
                              ? "outlined"
                              : "contained"
                          }
                          onClick={() => {
                            if (selectedNewDeliveryStatus === undefined) {
                              setSelectedNewDeliveryStatus(
                                OrderDeliveryStatus.New
                              );
                            } else {
                              setSelectedNewDeliveryStatus(undefined);
                            }
                          }}
                        >
                          {_.toUpper(
                            getOrderDeliveryStatusEnumTranslation(
                              OrderDeliveryStatus.New,
                              t
                            )
                          )}
                        </Button>
                      )}
                      {props.deliveryStatus === OrderDeliveryStatus.New && (
                        <Button
                          variant={
                            selectedNewDeliveryStatus === undefined
                              ? "outlined"
                              : "contained"
                          }
                          onClick={() => {
                            if (selectedNewDeliveryStatus === undefined) {
                              setSelectedNewDeliveryStatus(
                                OrderDeliveryStatus.ReadyForCollect
                              );
                            } else {
                              setSelectedNewDeliveryStatus(undefined);
                            }
                          }}
                        >
                          {_.toUpper(
                            getOrderDeliveryStatusEnumTranslation(
                              OrderDeliveryStatus.ReadyForCollect,
                              t
                            )
                          )}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    {_.toUpper(
                      getOrderDeliveryStatusEnumTranslation(
                        props.deliveryStatus,
                        t
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <span></span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={selectedNewDeliveryStatus === undefined}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
