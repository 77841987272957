import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import React from "react";
import moment from "moment";

export const CurrentTime: React.FC = (
) => {
  const [currentTime, setCurrentTime] = useState<string>('');

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss"))
    }, 1000);

  }, []);

  return (
      <Typography
        variant="h5"
        noWrap={true}
        width={"80px"}
        paddingLeft={"10px;"}
      >
        {currentTime}
      </Typography>
  );
};
