import _ from "lodash";
import { DEFAULT_USER_AUTH_TOKEN } from "./consts";
import { IOrderGetListResult } from "api/OrderApi/interfaces-result";

export const getStoredUserAuthToken: () => string = (): string => {
  const authToken: string | null =
    window.sessionStorage.getItem("UserAuthToken");
  if (authToken) {
    return authToken;
  }
  return DEFAULT_USER_AUTH_TOKEN;
};

export const getFormattedAddress: (data: IOrderGetListResult) => string = (
  data: IOrderGetListResult
): string => {
  const appartmentNoText: string = data.apartmentNo
    ? `/${data.apartmentNo}`
    : "";
  const zipCodeText: string = _.trim(_.trim(data.zipCode ?? "", "00-000"));
  const address = `${data.street ?? ""} ${
    data.streetNo ?? "-"
  }${appartmentNoText}, ${zipCodeText} ${data.city}`;
  return address;
};
