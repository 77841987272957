import { IPrivateRoutes, IPublicRoutes } from "./interfaces";

const PrivateRoutes: IPrivateRoutes = {
  orderList: "/order-list",
};

const PublicRoutes: IPublicRoutes = {
  login: "/login/:guid",
  default: "/",
};

export { PrivateRoutes, PublicRoutes };
