import { Box, CircularProgress, Container, Grid } from "@mui/material";
import { IUserLoginModel } from "api/UserApi/interfaces-model";
import { useUserApi } from "api/UserApi/user-api";
import useAuthorization from "hooks/use-authorization";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiResultStatus } from "utils/enums";
import { getStoredUserAuthToken } from "utils/helpers";
import { LoginFailed } from "./login-failed";

export const Login: () => JSX.Element = (): JSX.Element => {
  const { setAuthorize } = useAuthorization(getStoredUserAuthToken());
  const { login } = useUserApi();
  const { guid } = useParams();
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogin: () => Promise<void> = async (): Promise<void> => {
      if (guid) {
        let model: IUserLoginModel = {
          guid: guid,
        };
        setLoading(true);
        await login(model).then((response) => {
          if (response?.status === ApiResultStatus.Ok) {
            const newToken: string = response.data.token;
            setAuthorize(newToken);
            setIsSuccess(true);
            navigate("/order-list");
          } else {
            setIsSuccess(false);
          }
        });
      }
    };
    handleLogin();
  }, [guid]);

  useEffect(() => {
    setLoading(false);
  }, [isSuccess]);

  return (
    <Container maxWidth={false} sx={{ height: "100%" }}>
      {loading && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {!loading && !isSuccess && <LoginFailed />}
    </Container>
  );
};
