import { useBaseApi } from "api/base-api";
import { IUserApi } from "./interfaces";
import { IUserLoginModel } from "./interfaces-model";
import { IUserLoginResult } from "./interfaces-result";
import { IResultBaseGeneric } from "api/interfaces";

export const useUserApi: () => IUserApi = (): IUserApi => {
  const { basePostRequest } = useBaseApi();

  const login: (model: IUserLoginModel) => Promise<IResultBaseGeneric<IUserLoginResult>> = async (
    model: IUserLoginModel
  ): Promise<IResultBaseGeneric<IUserLoginResult>> => {
    let url: string = "/User/Login";
    return await basePostRequest<IUserLoginModel, IResultBaseGeneric<IUserLoginResult>>(
      url,
      model,
      false
    );
  };

  return { login };
};
