import { Container, Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Default: React.FC = () => {
  const { t } = useTranslation(["commonTranslation"]);

  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction={"row"}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={8}>
                <Typography
                  variant="h2"
                  color="primary"
                  sx={{ textAlign: "center" }}
                >
                  Kitchen Delivery Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={8}>
                <Typography color="secondary" sx={{ textAlign: "center" }}>
                  {t("defaultInformation")}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
