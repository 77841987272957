import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonTranslation_en from "translations/en/common-translation.json";
import commonTranslation_pl from "translations/pl/common-translation.json";
import employeeTranslation_en from "translations/en/employee-translation.json";
import employeeTranslation_pl from "translations/pl/employee-translation.json";
import enumTranslation_pl from "translations/pl/enum-translation.json";
import enumTranslation_en from "translations/en/enum-translation.json";
import orderTranslation_pl from "translations/pl/order-translation.json";
import orderTranslation_en from "translations/en/order-translation.json";

const resources = {
  en: {
    commonTranslation: commonTranslation_en,
    employeeTranslation: employeeTranslation_en,
    enumTranslation: enumTranslation_en,
    orderTranslation: orderTranslation_en,
  },
  pl: {
    commonTranslation: commonTranslation_pl,
    employeeTranslation: employeeTranslation_pl,
    enumTranslation: enumTranslation_pl,
    orderTranslation: orderTranslation_pl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "pl",
  keySeparator: ".",
  supportedLngs: ["en", "pl"],
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["cookie", "localStorage", "sessionStorage", "navigator"],
    lookupCookie: "i18nextLng",
    lookupLocalStorage: "i18nextLng",
    lookupSessionStorage: "i18nextLng",
    caches: ["cookie", "localStorage", "sessionStorage"],
    checkWhitelist: true,
  },
});

export default i18n;
