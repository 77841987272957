import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOrderApi } from "api/OrderApi/order-api";
import { DivIcon } from "leaflet";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { ApiResultStatus } from "utils/enums";
import { IOrderMarkerProps } from "./interfaces";
import { OrderDetailsPopup } from "./order-details-popup";
import { setOrderId, useOrderId } from "./order-events";
import { IOrderGetDetailsResult } from "api/OrderApi/interfaces-result";
import { css } from "@emotion/css";
import { CssBaseline } from "@mui/material";

export const OrderMarker: React.FC<IOrderMarkerProps> = (
  props: IOrderMarkerProps
) => {
  const { getDetails } = useOrderApi();
  const markerRef: MutableRefObject<any> = useRef();
  const icon = new DivIcon({
    html: ReactDOMServer.renderToString(
      <FontAwesomeIcon
        icon={faLocationDot}
        size="lg"
        className={
          css`
            color: ${props.markerData.color ?? "inherit"};
          ` + " icon-order-marker icon-marker"
        }
      />
    ),
    iconSize: [18, 24],
    iconAnchor: [18 / 2, 24],
    popupAnchor: [0, -24],
    tooltipAnchor: [0, -20],
  });
  const pointEPSG4326: proj4.TemplateCoordinates = [
    props.markerData.longitude ?? 0,
    props.markerData.latitude ?? 0,
  ];
  const selectedOrderId = useOrderId();
  const [orderDetails, setOrderDetails] = useState<IOrderGetDetailsResult>();

  const handleGetOrderDetails: () => void = (): void => {
    if (!orderDetails) {
      getDetails({ orderId: selectedOrderId }).then((response) => {
        if (response?.status === ApiResultStatus.Ok) {
          setOrderDetails(response.data);
        }
      });
    }
  };

  useEffect(() => {
    if (orderDetails) {
      props.mapRef.current?.panInside([
        props.markerData.latitude,
        props.markerData.longitude,
      ]);
      // props.mapRef.current?.flyTo(
      //   [props.markerData.latitude, props.markerData.longitude],
      //   14
      // );
      markerRef.current?.openPopup();
    }
  }, [orderDetails]);

  useEffect(() => {
    if (selectedOrderId === 0) {
      markerRef.current?.closePopup();
    } else if (selectedOrderId === props.markerData.id) {
      if (!orderDetails) {
        handleGetOrderDetails();
      } else {
        props.mapRef.current?.panInside([
          props.markerData.latitude,
          props.markerData.longitude,
        ]);
        // props.mapRef.current?.flyTo(
        //   [props.markerData.latitude, props.markerData.longitude],
        //   14
        // );
        markerRef.current?.openPopup();
      }
    }
  }, [selectedOrderId]);

  return (
    <Marker
      position={[pointEPSG4326[1], pointEPSG4326[0]]}
      key={`order${props.markerData.id},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
      icon={icon}
      ref={markerRef}
      eventHandlers={{
        click: () => {
          if (selectedOrderId !== props.markerData.id) {
            setOrderId(props.markerData.id);
          }
        },
        popupclose: () => {
          if (selectedOrderId === props.markerData.id) {
            setOrderId(0);
          }
        },
      }}
    >
      <OrderDetailsPopup orderDetails={orderDetails} />
      {props.markerData.tooltipText && (
        <Tooltip direction="top" permanent>
          {props.markerData.tooltipText}
        </Tooltip>
      )}
    </Marker>
  );
};
