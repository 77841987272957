import { useBaseApi } from "api/base-api";
import { ILocationApi, ILocationSetDefaultOrderReadyTimeInMinutesModel } from "./interfaces";
import { ILocationGetDetailsResult } from "./interfaces-result";
import { IResultBase, IResultBaseGeneric } from "api/interfaces";

export const useLocationApi: () => ILocationApi = (): ILocationApi => {
  const { baseGetRequest, basePostRequest } = useBaseApi();

  const getDetails: () => Promise<IResultBaseGeneric<ILocationGetDetailsResult>> =
    async (): Promise<IResultBaseGeneric<ILocationGetDetailsResult>> => {
      let url: string = "/Location/GetDetails";
      return await baseGetRequest<IResultBaseGeneric<ILocationGetDetailsResult>>(url);
    };

    const setDefaultOrderReadyTimeInMinutes: (model: ILocationSetDefaultOrderReadyTimeInMinutesModel) => Promise<IResultBase> =
    async (model: ILocationSetDefaultOrderReadyTimeInMinutesModel): Promise<IResultBase> => {
      let url: string = "/Location/SetDefaultOrderReadyTimeInMinutes";
      return await basePostRequest<ILocationSetDefaultOrderReadyTimeInMinutesModel, IResultBase>(url, model);
    };

  return { getDetails, setDefaultOrderReadyTimeInMinutes };
};
