import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";
import { map } from "rxjs";

export const [employeeClick$, setEmployeeId] = createSignal<number>();
export const [useEmployeeId, employeeId$] = bind(
  employeeClick$.pipe(
    map((value) => {
      return value;
    })
  ),
  0
);
