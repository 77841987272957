import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import createTheme, { ThemeOptions } from "@mui/material/styles/createTheme";
import { ThemeProvider } from "@mui/material";
import { Main } from "pages/main";
import "styles/index.scss";
import "configurations/i18n";
import { RouteApp } from "routes/route-app";
import { BrowserRouter as Router } from "react-router-dom";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#49313D",
      light: "#ab98a1",
    },
    secondary: {
      main: "#F05365",
    },
    background: {
      default: "#F2EDEB",
    },
    text: {
      primary: "rgba(12,8,10,0.85)",
    },
    error: {
      main: "#d32f2f",
      light: "#ff8787",
    },
    warning: {
      main: "#FABC2A",
    },
    info: {
      main: "#1678c5",
    },
    success: {
      main: "#5ad25f",
    },
    divider: "#7A5266",
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,
  },
};

const theme = createTheme(themeOptions);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <RouteApp />
    </Router>
    {/* <Main /> */}
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
