import {
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { EmployeeDeliveryStatus } from "utils/enums";
import { EmployeeColorContainer } from "./employee-color-container";
import { EmployeeCardGrid, EmployeeLastSyncText } from "../styles";
import { setEmployeeId, useEmployeeId } from "./employee-events";
import { IEmployeeCardProps } from "./interfaces";
import { css } from "@emotion/css";

export const EmployeeCard: React.FC<IEmployeeCardProps> = (
  props: IEmployeeCardProps
) => {
  const theme = useTheme();
  const isToday: boolean = props.lastDeviceLocalizationDate
    ? moment(props.lastDeviceLocalizationDate).diff(moment(), "minutes") > 30
    : false;
  const selectedEmployeeId = useEmployeeId();

  const handleCardClick: () => void = (): void => {
    setEmployeeId(props.id === selectedEmployeeId ? 0 : props.id);
  };

  return (
    <Card
      variant="outlined"
      onClick={handleCardClick}
      sx={{ borderWidth: props.id === selectedEmployeeId ? 4 : "1px" }}
    >
      <CardContent sx={{ padding: "2px !important" }}>
        <EmployeeCardGrid container sx={{ borderColor: props.color }}>
          <Grid container item xs={3}>
            <EmployeeColorContainer
              color={props.color}
              plannedReturnTime={props.plannedReturnTime}
              isWhiteFontColorEnabled={props.isWhiteFontColorEnabled}
            />
          </Grid>
          <Grid container item xs={9} padding={0.5}>
            <Grid item xs={10}>
              <Typography variant="h6" noWrap={true}>
                {props.firstName}{" "}
                {props.lastName ? `${props.lastName?.substring(0, 2)}.` : ""}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Chip
                color={
                  props.deliveryStatus === EmployeeDeliveryStatus.Avaliable
                    ? "success"
                    : props.deliveryStatus === EmployeeDeliveryStatus.OnRoad
                    ? "error"
                    : "warning"
                }
                style={{ height: "1.5rem", float: "right" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" noWrap={true}>
                z: {props.currentRouteDeliveredOrdersCount ?? 0} z{" "}
                {props.currentRouteOrdersCount ?? 0} /{" "}
                {props.plannedReturnTime ? (
                  <span
                    className={css`
                      font-weight: bold;
                      font-size: larger;
                    `}
                  >
                    {moment(props.plannedReturnTime).format("HH:mm")}
                  </span>
                ) : (
                  "--:--"
                )}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EmployeeLastSyncText
                variant="subtitle2"
                sx={{
                  color: isToday
                    ? theme.palette.text.primary
                    : theme.palette.error.main,
                  backgroundColor: isToday
                    ? "transparent"
                    : theme.palette.error.light,
                }}
              >
                synch.:{" "}
                {props.lastDeviceLocalizationDate
                  ? moment(props.lastDeviceLocalizationDate).format("HH:mm")
                  : "-"}
              </EmployeeLastSyncText>
            </Grid>
          </Grid>
        </EmployeeCardGrid>
      </CardContent>
    </Card>
  );
};
