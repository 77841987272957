import { Box, Checkbox, FormControlLabel, FormGroup, Paper } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrderListFilterProps } from "./interfaces";
import React from "react";
import { LocationDefaultReadyTimeChange } from "components/location/location-default-ready-time-change";
import { CurrentTime } from "components/common/current-time";
import { css } from "@emotion/css";
import { faL } from "@fortawesome/free-solid-svg-icons";

export const OrderListFilter: React.FC<IOrderListFilterProps> = (
  props: IOrderListFilterProps
) => {
  const { t } = useTranslation(["commonTranslation"]);
  const [showWithoutEmployee, setShowWithoutEmployee] = useState<boolean>(props.showWithoutEmployee);
  const [showDeliveredOrder, setShowDeliveredOrder] = useState<boolean>(props.showDeliveredOrder);
  const [showMap, setShowMap] = useState<boolean>(props.showMap);

  return (
    <Paper>
      <Box padding={"5px"}>
        <FormGroup row={true}>
          <FormControlLabel
            control={<CurrentTime />}
            label=""
            className={css`
              margin-left: 0;
              font-size: larger;
            `}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeliveredOrder}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  isChecked: boolean
                ) => {
                  setShowDeliveredOrder(isChecked);
                  if(isChecked){
                    setShowWithoutEmployee(false);
                    props.handleSetShowWithoutEmployeeOnClick(false);
                  }
                  props.handleSetShowDeliveredOrderOnClick(isChecked);
                }}
              />
            }
            label={`+${t("deliveredOrders")}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showWithoutEmployee}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  isChecked: boolean
                ) => {
                  setShowWithoutEmployee(isChecked);
                  if(isChecked){
                    setShowDeliveredOrder(false);
                    props.handleSetShowDeliveredOrderOnClick(false);
                  }
                  props.handleSetShowWithoutEmployeeOnClick(isChecked);
                }}
              />
            }
            label={t("withoutEmployee")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showMap}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  isChecked: boolean
                ) => {
                  setShowMap(isChecked);
                  props.handleShowMapOnClick(isChecked);
                }}
              />
            }
            label={t("showMap")}
          />
          <LocationDefaultReadyTimeChange
            defaultOrderReadyTimeInMinutes={
              props.defaultOrderReadyTimeInMinutes
            }
            handleSetDefaultOrderReadyTimeInMinutesOnSave={(
              defaultOrderReadyTimeInMinutes
            ) => {
              props.handleSetDefaultOrderReadyTimeInMinutesOnSave(
                defaultOrderReadyTimeInMinutes
              );
            }}
          />
        </FormGroup>
      </Box>
    </Paper>
  );
};
