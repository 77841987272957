import { faHelicopter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DivIcon } from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker } from "react-leaflet";
import { EmployeeMarkerDetailsPopup } from "./employee-marker-details-popup";
import { IEmployeeMarkerProps } from "./interfaces";

export const EmployeeMarker: React.FC<IEmployeeMarkerProps> = (
  props: IEmployeeMarkerProps
) => {
  const pointEPSG4326: proj4.TemplateCoordinates = [
    props.markerData.longitude ?? 0,
    props.markerData.latitude ?? 0,
  ];

  const icon = new DivIcon({
    html: ReactDOMServer.renderToString(
      <FontAwesomeIcon
        icon={faHelicopter}
        size="lg"
        className="icon-marker"
        color={props.markerData.color}
      />
    ),
    iconSize: [30, 24],
    iconAnchor: [30 / 2, 24],
    popupAnchor: [0, -24],
    tooltipAnchor: [0, -20],
  });

  return (
    <Marker
      position={[pointEPSG4326[1], pointEPSG4326[0]]}
      key={`employee${props.markerData.id},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
      icon={icon}
    >
      <EmployeeMarkerDetailsPopup phoneNumber={props.markerData.tooltipText} />
    </Marker>
  );
};
