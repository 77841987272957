import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { Popup } from "react-leaflet";
import { IEmployeeMarkerDetailsProps } from "./interfaces";

export const EmployeeMarkerDetailsPopup: React.FC<IEmployeeMarkerDetailsProps> =
  (props: IEmployeeMarkerDetailsProps) => {
    return (
      <Popup>
        <Grid container sx={{ width: 250 }} rowSpacing={0.5}>
          <Grid item xs={2}>
            <FontAwesomeIcon icon={faPhone} />
          </Grid>
          <Grid item xs={10}>
            {props.phoneNumber ?? "-"}
          </Grid>
        </Grid>
      </Popup>
    );
  };
