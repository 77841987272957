import { Default } from "pages/default";
import { Login } from "pages/login";
import { Main } from "pages/main";
import { Route, Routes } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./routes";

export const RouteApp: () => JSX.Element = (): JSX.Element => {
  return (
    <Routes>
      <Route path={PrivateRoutes.orderList} element={<Main />} />
      <Route path={PublicRoutes.login} element={<Login />} />
      <Route path={PublicRoutes.default} element={<Default />} />
    </Routes>
  );
};
